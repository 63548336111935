export const DEFAULT_SIZE = '32';
export const ALLOWED_SIZES = ['20', DEFAULT_SIZE, '36'] as const;

export type AllowedSizes = (typeof ALLOWED_SIZES)[number];

export const ICON_SIZE = {
  20: '12',
  32: '16',
  36: '20',
};

export const EMOJI_TEXT_CLASS = {
  20: 'body-text-xs',
  32: 'body-text-medium',
  36: 'body-text-l',
};

export const LETTER_TEXT_CLASS = {
  20: 'body-text-xs',
  32: 'body-text-l',
  36: 'body-text-l',
};
