import { ValueOf } from 'teamtailor/utils/type-utils';
import ModelRegistry from 'ember-data/types/registries/model'; // eslint-disable-line ember/use-ember-data-rfc-395-imports

export type ModelKey = keyof ModelRegistry;
export type AllModels = ValueOf<ModelRegistry>;
export type PushPayloadArg = {
  [K in ModelKey]: ModelRegistry[K][];
};

export { default as ActivityModel } from './activity';
export { default as AnswerModel } from './answer';
export { default as ApiKeyModel } from './api-key';
export { default as ApprovalSettingModel } from './approval-setting';
export { default as AssetStructureModel } from './asset-structure';
export { default as AssessmentModel } from './assessment';
export { default as AuditEventModel } from './audit-event';
export { default as AuditLogExportModel } from './audit-log-export';
export { default as AuditLogModel } from './audit-log';
export { default as AuthorizationModel } from './authorization';
export { default as AutoJoinDomainModel } from 'teamtailor/models/auto-join-domain';
export { default as AutomaticDeletionSettingsModel } from './automatic-deletion-setting';
export { default as AvailableCompanyModel } from './available-company';
export { default as AvatarModel } from './avatar';
export { default as BatchJobModel } from 'teamtailor/models/batch-job';
export { default as BlockLayoutModel } from './block-layout';
export { default as BlocketCategoryModel } from './blocket-category';
export { default as BlocketMunicipalityModel } from './blocket-municipality';
export { default as BlocketRegionModel } from './blocket-region';
export { default as BlocketSubcategoryModel } from './blocket-subcategory';
export { default as BlogModel } from './blog';
export { default as CandidateCounterDepartmentModel } from './candidate-counter/department';
export { default as CandidateModel } from './candidate';
export { default as CannedResponseModel } from './canned-response';
export { default as CareerSiteHeaderModel } from './career-site-header';
export { default as CareerSiteModel } from './career-site';
export { default as ChannelActivationModel } from './channel-activation';
export { default as ChannelModel } from './channel';
export { default as ChargeModel } from './charge';
export { default as CompanyModel } from './company';
export { default as ConnectMediaItemModel } from './connect-media-item';
export { default as ConnectSettingModel } from './connect-setting';
export { default as ConnectedCalendarModel } from './connected-calendar';
export { default as ConnectedConferenceModel } from './connected-conference';
export { default as ContentBlockPictureModel } from './content-block-picture';
export { default as ConversationModel } from './conversation';
export { default as CookieDetailModel } from './cookie-detail';
export { default as CookieSettingModel } from './cookie-setting';
export { default as CopilotFeedbackModel } from './copilot-feedback';
export { default as CopilotSettingModel } from './copilot-setting';
export { default as CountryModel } from './country';
export { default as CreditTransactionModel } from './credit-transaction';
export { default as CustomFieldModel } from './custom-field';
export { default as CustomReportModel } from './custom-report';
export { default as DashboardSettingModel } from './dashboard-setting';
export { default as DashboardWidgetModel } from './dashboard-widget';
export { default as DataPrivacySettingModel } from './data-privacy-setting';
export { default as DataRequestModel } from './data-request';
export { default as DataRequestSettingModel } from './data-request-setting';
export { default as DefaultNotificationConfigModel } from 'teamtailor/models/default-notification-config';
export { default as DepartmentModel } from './department';
export { default as DepartmentOrRoleModel } from './department-or-role';
export { default as DesignModel } from './design';
export { default as DivisionModel } from './division';
export { default as DomainModel } from './domain';
export { default as EeoReportSettingModel } from './eeo-report-setting';
export { default as EmployeeDashboardModel } from 'teamtailor/models/employee-dashboard';
export { default as FeatureModel } from './feature';
export { default as FinnIndustryModel } from './finn-industry';
export { default as FinnOccupationModel } from './finn-occupation';
export { default as FormAnswerModel } from './form-answer';
export { default as FormInviteModel } from './form-invite';
export { default as FormModel } from './form';
export { default as FormQuestion } from './form-question';
export { default as FormQuestionModel } from './form-question';
export { default as GiphyImageModel } from './giphy-image';
export { default as GroupSiteCompanyModel } from './group-site-company';
export { default as HireQualityResponseModel } from './hire-quality-response';
export { default as ImageModel } from './image';
export { default as ImageWithSettingModel } from './image-with-setting';
export { default as IndustryModel } from './industry';
export { default as InsightsReportModel } from './insights-report';
export { default as InterviewKitModel } from './interview-kit';
export { default as InterviewModel } from './interview';
export { default as JobApplicationModel } from './job-application';
export { default as JobApplicationTriggerModel } from './job-application-trigger';
export { default as JobDetailModel } from './job-detail';
export { default as JobModel } from './job';
export { default as JobOfferApprovalFlowModel } from './job-offer-approval-flow';
export { default as JobOfferFormModel } from './job-offer-form';
export { default as JobOfferModel } from './job-offer';
export { default as LinkedinSettingModel } from 'teamtailor/models/linkedin-setting';
export { default as LocationModel } from './location';
export { default as LoginModel } from './login';
export { default as MeetingEventAttendeeModel } from './meeting-event-attendee';
export { default as MeetingEventLocationModel } from './meeting-event-location';
export { default as MeetingEventModel } from './meeting-event';
export { default as MeetingEventStatusModel } from './meeting-event-status';
export { default as MeetingModel } from './meeting';
export { default as MeetingRoomModel } from './meeting-room';
export { default as MeetingRoomResourceModel } from './meeting-room-resource';
export { default as MessageModel } from './message';
export { default as MessageWidgetModel } from './message-widget';
export { default as MonsterJobCategoryModel } from './monster-job-category';
export { default as MonsterJobIndustryModel } from './monster-job-industry';
export { default as MonsterJobOccupationModel } from './monster-job-occupation';
export { default as MoveModel } from './move';
export { default as NavigationItemModel } from './navigation-item';
export { default as NoteModel } from './note';
export { default as NotificationConfigModel } from './notification-config';
export { default as NotificationSettingModel } from './notification-setting';
export { default as NpsResponseModel } from './nps-response';
export { default as NurtureCampaignModel } from './nurture-campaign';
export { default as NurtureCampaignRecipientModel } from './nurture-campaign-recipient';
export { default as NurtureCampaignStepEmailModel } from './nurture-campaign-step/email';
export { default as NurtureCampaignStepWaitModel } from './nurture-campaign-step/wait';
export { default as OccupationFieldModel } from './occupation-field';
export { default as OccupationGroupModel } from './occupation-group';
export { default as OccupationNameModel } from './occupation-name';
export { default as OrganizableModel } from './organizable';
export { default as OverdueJobApplicationModel } from './overdue-job-application';
export { default as PageModel } from './page';
export { default as PagePublicationModel } from './page-publication';
export { default as PartnerActivationModel } from './partner-activation';
export { default as PartnerModel } from './partner';
export { default as PartnerResultModel } from './partner-result';
export { default as PaymentProcessorSettingModel } from './payment-processor-setting';
export { default as PermissionModel } from './permission';
export { default as PhoneNumberModel } from './phone-number';
export { default as PhoneNumberSetupModel } from './phone-number-setup';
export { default as PickedCustomFieldModel } from './picked-custom-field';
export { default as PickedDashboardWidgetModel } from './picked-dashboard-widget';
export { default as PickedImageModel } from './picked-image';
export { default as PickedInterviewKitModel } from './picked-interview-kit';
export { default as PickedLocationModel } from './picked-location';
export { default as PickedQuestionModel } from './picked-question';
export { default as PickedVideoModel } from './picked-video';
export { default as PostModel } from './post';
export { default as PredictionModel } from './prediction';
export { default as PreparedMessageModel } from './prepared-message';
export { default as PrivacyPolicyModel } from './privacy-policy';
export { default as PromotionModel } from './promotion';
export { default as PromotionRequestModel } from './promotion-request';
export { default as QuestionDetailModel } from './question-detail';
export { default as QuestionModel } from './question';
export { default as QuestionnaireModel } from './questionnaire';
export { default as QuickRejectSettingModel } from './quick-reject-setting';
export { default as ReactionModel } from './reaction';
export { default as RecruitingFirmModel } from './recruiting-firm';
export { default as ReferenceFormModel } from './reference-form';
export { default as ReferenceModel } from './reference';
export { default as ReferenceTemplateModel } from './reference-template';
export { default as ReferralModel } from './referral';
export { default as RegionModel } from './region';
export { default as RejectReasonModel } from './reject-reason';
export { default as RequisitionArchiveReasonModel } from './requisition-archive-reason';
export { default as RequisitionFlowModel } from './requisition-flow';
export { default as RequisitionFlowStepModel } from './requisition-flow-step';
export { default as RequisitionModel } from './requisition';
export { default as RequisitionSettingModel } from './requisition-setting';
export { default as RequisitionStepModel } from './requisition-step';
export { default as RequisitionStepVerdictCommentModel } from './requisition-step-verdict-comment';
export { default as RequisitionStepVerdictModel } from './requisition-step-verdict';
export { default as ReviewModel } from './review';
export { default as RoleModel } from './role';
export { default as RolePermissionModel } from './role-permission';
export { default as SandboxActivationModel } from 'teamtailor/models/sandbox-activation';
export { default as ScheduledReportModel } from './scheduled-report';
export { default as ScorecardCommentModel } from './scorecard-comment';
export { default as ScorecardCriteriumModel } from './scorecard-criterium';
export { default as ScorecardPickModel } from './scorecard-pick';
export { default as ScorecardScoreModel } from './scorecard-score';
export { default as SearchModel } from './search';
export { default as SearchResultModel } from './search-result';
export { default as SectionChangeModel } from './section-change';
export { default as SectionItemModel } from './section-item';
export { default as SectionModel } from './section';
export { default as SegmentModel } from './segment';
export { default as SelectedCandidateDisplayColumnModel } from './selected-candidate-display-column';
export { default as ShareLinkModel } from './share-link';
export { default as ShareLinksJobModel } from './share-links-job';
export { default as SharedItemModel } from './shared-item';
export { default as SingleSignOnModel } from './single-sign-on';
export { default as SlackAppModel } from 'teamtailor/models/slack-app';
export { default as SmsSettingModel } from './sms-setting';
export { default as SourceModel } from './source';
export { default as SsoUserAttributeMappingModel } from './sso-user-attribute-mapping';
export { default as StageModel } from './stage';
export { default as StageNameModel } from './stage-name';
export { default as StageTypeModel } from './stage-type';
export { default as StoryModel } from './story';
export { default as SubscriptionContractModel } from './subscription-contract';
export { default as SubscriptionInvoiceModel } from 'teamtailor/models/subscription-invoice';
export { default as SurveyModel } from './survey';
export { default as TagModel } from './tag';
export { default as TaggingModel } from './tagging';
export { default as TeamModel } from './team';
export { default as TermsOfServiceSettingModel } from 'teamtailor/models/terms-of-service-setting';
export { default as TodoModel } from './todo';
export { default as TodoTemplateModel } from './todo-template';
export { default as TranslationCannedResponseModel } from './translation/canned-response';
export { default as TranslationModel } from './translation';
export { default as TriggerModel } from './trigger';
export { default as TriggerSmartMoveModel } from './trigger/smart-move';
export { default as TwilioModel } from './twilio';
export { default as UnsplashPhotoModel } from './unsplash-photo';
export { default as UploadModel } from './upload';
export { default as UptrailCategoryModel } from './uptrail-category';
export { default as UptrailRegionModel } from './uptrail-region';
export { default as UserInvitationModel } from './user-invitation';
export { default as UserModel } from './user';
export { default as UserTemplateModel } from './user-template';
export { default as VideoMeetingModel } from './video-meeting';
export { default as VideoMeetingTokenModel } from './video-meeting-token';
export { default as VideoModel } from './video';
export { default as VideoRoomModel } from './video-room';
export { default as WalletModel } from './wallet';
export { default as WebhookSubscriptionModel } from './webhook-subscription';
export { default as LatestReadJobCommentModel } from './latest-read-job-comment';
export { default as GlobalCollaborateMessageModel } from './global-collaborate-message';
