import Model, {
  belongsTo,
  hasMany,
  attr,
  AsyncBelongsTo,
  AsyncHasMany,
} from '@ember-data/model';
import {
  CompanyModel,
  DepartmentModel,
  UserModel,
  JobModel,
} from 'teamtailor/models';

export const NO_DIVISION_ID = '1000000000';

export default class DivisionModel extends Model {
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('user') declare manager: AsyncBelongsTo<UserModel>;

  @hasMany('department') declare departments: AsyncHasMany<DepartmentModel>;
  @hasMany('job') declare jobs: AsyncHasMany<JobModel>;

  @attr('carrierwave') declare logo: string | null;
  @attr('carrierwave-cache') declare logoCache: string | null;
  @attr('string') declare name: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    division: DivisionModel;
  }
}
