import AvatarModel from 'teamtailor/models/avatar';
import JobModel from 'teamtailor/models/job';

export type TempAvatarData = Omit<AvatarModel, 'id' | 'job'>;

export const ALLOWED_AVATAR_COLORS = [
  'zinc',
  'red',
  'indigo',
  'light-blue',
  'cyan',
  'lime',
  'amber',
  'rose',
];

export const DEFAULT_AVATAR_ATTRIBUTES = {
  avatarType: 'letter',
  backgroundColor: 'zinc',
  chosenIcon: '',
  chosenIconPrefix: '',
  chosenEmoji: '',
} as TempAvatarData;

export function colorForJob(job?: JobModel) {
  if (job?.id) {
    const index = parseInt(job.id) % ALLOWED_AVATAR_COLORS.length;
    return ALLOWED_AVATAR_COLORS[index];
  }
}
